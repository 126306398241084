<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <title>SREE</title>
    <link rel="stylesheet" href="../login/login.component.css">
  </head>

  <body>
    <app-header></app-header>
    <div class="login-box">
      <h1>Iniciar sesión</h1>
      <form (submit)="iniciarSesion()">
        <!-- USERNAME INPUT -->
        <label for="username">Numero de Empleado</label>
        <input type="text" placeholder="Ingrese su Numero de Empleado" name="nroCuenta" [(ngModel)]="nroEmpleado">
        <!-- PASSWORD INPUT -->
        <label for="password">Contraseña</label>
        <input type="password" placeholder="Ingrese su contraseña" name="contra" [(ngModel)]="contra">
        <button type="submit" class="">Log In</button>
        <a href="#">Olvidaste tu contraseña?</a><br>
      </form>
    </div>
    
  </body>
</html>